<template>
    <textarea
        v-if="textarea"
        ref="input"
        v-bind="$attrs"
        class="base-input"
        :class="{
            'base-input--block': block,
        }"
        v-on="inputListeners"
    />

    <label
        v-else
        class="base-input"
        :class="{
            'base-input--block': block,
            'base-input--clearable': clearable,
            'base-input--with-icon': $slots.icon,
            'base-input--with-postfix': $slots.postfix,
            'base-input--with-prefix': $slots.prefix,
        }"
    >
        <slot name="icon" />

        <span v-if="$slots.prefix" class="base-input__prefix">
            <slot name="prefix" />
        </span>

        <input ref="input" v-bind="$attrs" v-on="inputListeners" />

        <span v-if="clearable" class="base-input__clear">
            <RemoveIcon width="1em" height="1em" @click="handleClear" />
        </span>

        <span v-if="$slots.postfix" class="base-input__postfix">
            <slot name="postfix" />
        </span>
    </label>
</template>

<script>
import { debounce } from 'lodash'

import RemoveIcon from '../icons/RemoveIcon'

export default {
    name: 'BaseInput',
    components: {
        RemoveIcon,
    },
    inheritAttrs: false,
    props: {
        block: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        debounce: {
            type: Number,
            default: undefined,
        },
        textarea: {
            type: Boolean,
            default: false,
        },
        trim: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        inputListeners() {
            return {
                ...this.$listeners,
                input: this.debounce
                    ? debounce(this.handleInput, this.debounce)
                    : this.handleInput,
            }
        },
    },
    methods: {
        blur() {
            this.$refs.input.blur()
        },
        focus() {
            this.$refs.input.focus()
        },
        handleClear() {
            this.$refs.input.value = ''
            this.$emit('input', '')
        },
        handleInput(event) {
            const value = this.trim
                ? event.target.value.trim()
                : event.target.value
            this.$emit('input', value)
        },
    },
}
</script>

<style lang="scss" scoped>
$block-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

@mixin input-style {
    padding: 12px;
    width: 100%;
    background-color: $color-gray-lighter-new;
    border: none;
    border-radius: 4px;
    line-height: 16px;
    font-size: 14px;
    font-family: 'IBM Plex Sans', sans-serif;
    color: $color-text-new;
    outline: none;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        margin: 0;
        -webkit-appearance: none;
    }

    &::placeholder {
        color: rgba(0, 0, 0, 0.4);
    }
}

@mixin input-style-block {
    padding: 10px 12px;
    background-color: #fff;
    box-shadow: $block-shadow;
    border-radius: 8px;
    line-height: 20px;
}

.base-input {
    position: relative;
    display: block;
    background-color: $color-gray-lighter-new;
    border-radius: 4px;

    & > svg {
        position: absolute;
        top: 12px;
        left: 12px;
        cursor: text;
    }

    input {
        @include input-style;
    }

    &--block {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: $block-shadow;

        input {
            @include input-style-block;
            box-shadow: none;
        }
    }

    &--with-icon {
        input {
            padding-left: 38px;
        }
    }

    &--clearable,
    &--with-postfix,
    &--with-prefix {
        display: flex;
    }

    &--with-postfix {
        input {
            padding-right: 4px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            text-align: right;

            &::placeholder {
                text-align: left;
            }
        }
    }

    &--with-prefix {
        input {
            padding-left: 4px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &__clear {
        display: flex;
        align-items: center;
        padding-right: 12px;

        & > svg {
            cursor: pointer;
            transition: opacity 0.1s;

            &:hover {
                opacity: 0.75;
            }
        }
    }

    &__postfix,
    &__prefix {
        margin: 0;
        padding: 12px;
        line-height: 16px;
        font-size: 14px;
        color: $color-text-new;
        white-space: nowrap;
        cursor: text;
    }

    &__postfix {
        padding-left: 0;
    }

    &__prefix {
        padding-right: 0;
    }
}

textarea {
    &.base-input {
        @include input-style;
        min-width: 100%;
        max-width: 100%;
        min-height: 40px;

        &--block {
            @include input-style-block;
        }
    }
}
</style>
